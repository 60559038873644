import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

export class Header extends Component {
  state = {
    currentItemIndex: 0
  };

  setCurrentItem(configuratorItem, index) {
    this.setState({ currentItemIndex: index });
    this.props.setItem(configuratorItem);
  }

  render() {
    const { currentItemIndex } = this.state;
    const { brandLogoUrl, configuratorItems } = this.props;
    return (
      <header id={"main-header"} className={"p-2"}>
        <div className="row justify-content-center">
          <div className="col-auto">
            <img src={brandLogoUrl} alt=""/>
          </div>
          <div className="col"></div>
          <div className="col-auto">
            <ul className="list-group list-group-horizontal pt-3 float-end">
              {configuratorItems.map((configuratorItem, index) => (
                  <li className={"list-group-item py-1 align-middle " + (currentItemIndex === index ? "current" : "")}
                      key={index}>
                    <button onClick={() => this.setCurrentItem(configuratorItem, index)}>
                      {configuratorItem.productVersion ? configuratorItem.productVersion.name : ""}
                    </button>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
