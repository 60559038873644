import React, { Component } from "react";
import VersionColors from "./VersionColors";

// import "bootstrap/dist/css/bootstrap.min.css";

const formatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "MAD"

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export class ConfigurableItem extends Component {
  state = {
    testDriveRequestBtnDisabled: false,
    receiveTechnicalDocBtnDisabled: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.item !== prevProps.item || this.props.currentParticipant !== prevProps.currentParticipant) {
      this.setState({
        testDriveRequestBtnDisabled: false,
        receiveTechnicalDocBtnDisabled: false
      });
    }
  }

  render() {
    const { testDriveRequestBtnDisabled, receiveTechnicalDocBtnDisabled } = this.state;
    const { item } = this.props;

    return (
      <div className={"configurator-item"}>
        <div className="row">
          <div className="header col-6 pt-5 pb-3">
            <div className="row justify-content-center">
              <div className="col-auto">
                <h1 className={"title"}>{item.product.name}</h1>
                <h2 className={"subtitle"}>{item.productVersion.name}</h2>
              </div>
            </div>
            <div className="col pr-0">
              <VersionColors
                colors={item.productVersion.colors}/>
            </div>
          </div>
          <div className="col">
            <div className={"content py-4 pr-4"}>
              <h2 className={"equipements-title"}>ÉQUIPEMENTS</h2>
              <div className={"equipements-content mb-2"} dangerouslySetInnerHTML={{ __html: item.equipments }}></div>
              <div className="row no-gutters justify-content-center align-items-center align-content-center">
                <div className="col-auto py-3">
                  <button className={"test-drive-btn"} disabled={testDriveRequestBtnDisabled}
                          onClick={() => {
                            this.props.requestTestDrive(item);
                            this.setState({ testDriveRequestBtnDisabled: true });
                          }}>Réserver un essai
                  </button>
                </div>
                <div className="col-auto py-3 pl-2">
                  <button className={"send-via-email-btn"}
                          onClick={() => {
                            this.props.receiveTechnicalDocumentViaEmail(item);
                            this.setState({ receiveTechnicalDocBtnDisabled: true });
                          }} disabled={receiveTechnicalDocBtnDisabled}>
                    <span className="row no-gutters justify-content-center align-items-center align-content-center">
                      <span className="col-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             className="bi bi-envelope" viewBox="0 0 16 16">
                          <path
                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                        </svg>
                      </span>
                      <span className="col pl-2">
                        Recevoir la fiche<br/>technique via email
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="price-container">
                <p className={"start-from m-0"}>À partir de</p>
                <p className={"price m-0"}>
                  {(formatter.format(item.price)).replace("MAD", "")}<span className={"currency"}>DHS</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigurableItem;
