import React, {Component} from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export class Identify extends Component {
  state = {
    layoutName: "default",
    focus: "",
    deviceUID: window.localStorage.getItem('deviceUID'),
  };

  onChange = value => {
    const {focus} = this.state;
    if (focus === 'deviceUID') {
      this.setState({
        deviceUID: value
      });
    }
  };

  onBlur = ({target}) => {
    this.setState({focus: target.name});
  };

  onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") {
      this.handleShift();
    }
  };

  handleShift = () => {
    const {layoutName} = this.state;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  onChangeInput = ({target}) => {
    const {name, value} = target;
    if (name === 'deviceUID') {
      this.setState(
        {
          deviceUID: value
        },
        () => {
          this.keyboard.setInput(value);
        }
      );
    }
  };

  onFocus = ({target}) => {
    this.setState({focus: target.name}, () => this.keyboard.setInput(""));
  };

  handleSubmit() {
    window.localStorage.setItem('deviceUID', this.state.deviceUID);
    this.props.getConfig();
  }

  render() {
    const {deviceUID, layout} = this.state;
    return (
      <div className="form-container">
        <h1 className="title">Identify this device</h1>
        <div className="preview-form-wrapper">
          <div className="keyboard-and-inputs">
            <div className="inputs">
              <div className="name-inputs">
                <input
                  value={deviceUID}
                  placeholder={"Device UID"}
                  onChange={e => this.onChangeInput(e)}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  name="deviceUID"
                />
              </div>
            </div>
            <Keyboard
              keyboardRef={r => (this.keyboard = r)}
              layoutName={this.state.layoutName}
              onChange={value => this.onChange(value)}
              onKeyPress={button => this.onKeyPress(button)}
              layout={layout}
            />
          </div>
          <div className="last-preview">
            <div className="last-preview-action-buttons">
              <button onClick={() => this.handleSubmit()}>SEND</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Identify;
