import React, { Component } from "react";

// import "bootstrap/dist/css/bootstrap.min.css";


export class VersionColors extends Component {
  state = {
    currentImageColorIndex: null
  };

  componentDidMount() {
    if (this.props.colors) {
      this.setState({ currentImageColorIndex: 0 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.colors !== this.props.colors) {
      console.log("colors props has changed.");
      if (this.props.colors) {
        this.setState({ currentImageColorIndex: 0 });
      }
    }
  }

  setCurrentImageColorItem = (index) => {
    this.setState({ currentImageColorIndex: index });
  };

  render() {
    const { colors } = this.props;
    const { currentImageColorIndex } = this.state;

    return (
      <div>
        <div className="images-container">
          {colors && colors.map((imageColorItem, index) => (
              <img key={index} src={imageColorItem.image ? imageColorItem.image.contentUrl : ""} className={index === currentImageColorIndex ? 'current': ''} alt={''}/>
            )
          )}
        </div>
        <p style={{ textAlign: "center", fontSize: '22px' }}>Choisissez votre couleur</p>
        <div className={"row justify-content-center colors1"}>
          {colors && colors.map((imageColorItem, index) => (
            <div className="col-auto px-1" key={index}>
              <button className={"color-btn " + (index === currentImageColorIndex ? "current" : "")}
                 onClick={() => this.setCurrentImageColorItem(index)}
                 style={{ backgroundColor: imageColorItem.colorValue }}></button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default VersionColors;
