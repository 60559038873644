import React, { Component } from "react";
import "./App.css";
import axiosInstance from "./axiosInstance";
import Identify from "./components/Identify";
import ConfigurableItem from "./components/ConfigurableItem";
import "bootstrap/dist/css/bootstrap.min.css";
import ScanMessage from "./components/ScanMessage";
import Header from "./components/Header";

let NFCReadChars = [];

export class App extends Component {
  timeOutTimer = null;
  state = {
    isStandalone: true,
    deviceUID: window.location.pathname.slice(1) ? window.location.pathname.slice(1) : window.localStorage.getItem("deviceUID"),
    device: null,
    config: null,
    timeOutDuration: 60000,
    currentParticipant: null,
    showScanMessage: true,
    currentConfiguratorItem: null,
    technicalDocSentSuccessfully: false,
    technicalDocSentError: false,
    testDriveRequestSentSuccessfully: false,
    testDriveRequestSentError: false
  };

  resetTimeOut = () => {
    console.log("resetTimeOut");
    if (this.state.currentParticipant === null) {
      return;
    }
    clearTimeout(this.timeOutTimer);
    //TODO: get wallTimeout if not null else use 60000
    this.timeOutTimer = setTimeout(
      () => this.timeOut(),
      this.state.timeOutDuration
    );
  };

  timeOut = () => {
    console.log("timeout session expired");
    if (this.state.currentParticipant !== null) {
      // TODO: track session's duration
      this.setState({ currentParticipant: null, showScanMessage: true });
    }
  };

  getConfig = () => {
    const { deviceUID } = this.state;
    if (typeof deviceUID !== "undefined" && deviceUID !== null) {
      this.setState({ deviceUID: deviceUID });
    }
    if (deviceUID) {
      axiosInstance.get("/devices/" + deviceUID).then(({ data: device }) => {
        if (device) {
          this.setState({ device });
          axiosInstance.get(device.configuratorConfig).then(async ({ data: config }) => {
            if (config) {
              // duplicate colors issue workaround:
              for (const ci of config.configuratorItems) {
                const colors = [];
                for (const pc of ci.productVersion.colors) {
                  let c = colors.find(o => o.id === pc.id);
                  if (typeof c === 'undefined') {
                    colors.push(pc);
                  }
                }
                ci.productVersion.colors = colors;
              }

              const imagesProperties = ["mainBackgroundImage", "scanIcon", "brandLogo"];
              const urlSearchParams = new URLSearchParams();
              for (const imgProperty of imagesProperties) {
                if (typeof config[imgProperty] !== "undefined") {
                  urlSearchParams.append("id[]", config[imgProperty]);
                }
              }
              for (const ci of config.configuratorItems) {
                for (const pc of ci.productVersion.colors) {
                  if (typeof pc.image !== "undefined") {
                    urlSearchParams.append("id[]", pc.image);
                  }
                }
              }
              const images = (await axiosInstance.get("/images?perPage=1000&" + urlSearchParams.toString())).data["hydra:member"];
              for (const image of images) {
                for (const imgProperty of imagesProperties) {
                  if (config[imgProperty] === image["@id"]) {
                    config[imgProperty] = image;
                    break;
                  }
                }
                for (const ci of config.configuratorItems) {
                  for (const pc of ci.productVersion.colors) {
                    if (pc.image === image["@id"]) {
                      pc.image = image;
                    }
                  }
                }
              }
              console.log("config:", config);
              this.setState({ config });
              if (config.configuratorItems.length > 0) {
                config.configuratorItems = config.configuratorItems.sort((a, b) =>
                  typeof a.position !== "undefined" ? (a.position > b.position
                    ? 1
                    : a.position === b.position
                      ? a.id > b.id
                        ? 1
                        : -1
                      : -1) : 1
                );
                for (let ci of config.configuratorItems) {
                  ci.productVersion.colors = ci.productVersion.colors.sort((a, b) =>
                    typeof a.position !== "undefined" ? (a.position > b.position
                      ? 1
                      : a.position === b.position
                        ? a.id > b.id
                          ? 1
                          : -1
                        : -1) : 1
                  );
                }
                this.setState({ currentConfiguratorItem: config.configuratorItems[0] });
              }
              this.initMercureHub();
              this.activateNFCReader();
              const head =
                document.head || document.getElementsByTagName("head")[0],
                style = document.createElement("style");
              head.appendChild(style);

              if (style.type) {
                style.type = "text/css";
              }
              if (style.styleSheet) {
                // This is required for IE8 and below.
                style.styleSheet.cssText = config.cssStyle;
              } else {
                style.appendChild(document.createTextNode(config.cssStyle));
              }
            }
          });
        }
      });
    }
  };

  componentDidMount() {
    this.getConfig();
    document.body.addEventListener("touchstart", this.resetTimeOut);
    document.body.addEventListener("touchend", this.resetTimeOut);
  }

  componentWillUnmount() {
    document.body.removeEventListener("touchstart", this.resetTimeOut);
    document.body.removeEventListener("touchend", this.resetTimeOut);
  }

  async initMercureHub() {
    const { device } = this.state;
    const url = new URL(process.env.REACT_APP_MERCURE_HUB_URL);
    const restartAppTopic = "device/" + device.deviceUID + "/RESTART_APP";
    const NFCReadTopics = [];
    url.searchParams.append("topic", restartAppTopic);
    if (device.deviceCircle != null) {
      if (typeof device.deviceCircle !== "object") {
        try {
          device.deviceCircle = (await axiosInstance.get(device.deviceCircle)).data;
        } catch (e) {
        }
      }
      this.setState({ device: device });
    }
    if (typeof device.deviceCircle !== "undefined" && device.deviceCircle !== null) {
      for (const d of device.deviceCircle.devices) {
        if (typeof d !== "object" || d.type.slug !== "nfc-activator") {
          continue;
        }
        const t = "device/" + d.deviceUID + "/NFC_READ";
        NFCReadTopics.push(t);
        url.searchParams.append("topic", t);
      }
    }
    this.eventSource = new EventSource(url);
    this.eventSource.onmessage = async e => {
      console.log(e);
      console.log("mercury event response: ", e.data);
      const payload = JSON.parse(e.data);
      if (NFCReadTopics.indexOf(payload.topic) > -1) {
        if (
          payload.data.participant !== null &&
          typeof payload.data.participant !== "undefined"
        ) {
          const person = payload.data.participant;
          const activateAction = { person: person["@id"], project: device.project, device: device["@id"] };
          axiosInstance({
            method: "POST",
            url: "/activate_actions",
            data: JSON.stringify(activateAction)
          });
          console.log("new participant activation");
          console.log(person);
          this.setState({ currentParticipant: person, showScanMessage: false });
          this.resetTimeOut();
        } else {
          console.log("La carte n'est pas enregistré.");
        }
      } else if (payload.topic === restartAppTopic) {
        console.log("received " + restartAppTopic);
        console.log(payload);
        window.location.reload();
      } else {
        console.log("unhandled topic");
      }
    };
  }

  showScanMessage() {
    console.log("showScanMessage");
    this.setState({ showScanMessage: true });
    setTimeout(() => {
      this.setState({ showScanMessage: false });
    }, 10000);
  }

  activateNFCReader = () => {
    window.document.addEventListener("keyup", this.handleNFCReadKeyUp);
  };

  deactivateNFCReader = () => {
    window.document.removeEventListener("keyup", this.handleNFCReadKeyUp);
  };

  handleNFCReadKeyUp = (event) => {
    const keyCode = ("which" in event) ? event.which : event.keyCode;
    if (event.keyCode === 13) {
      let numStr = NFCReadChars.join("");
      NFCReadChars = [];
      console.log(numStr);
      const hex = Number(numStr).toString(16);
      //console.log(hex);
      // --------
      const listHex = [];
      const hexStr = hex.toString();
      for (var i = 0; i < hexStr.length; i++) {
        var tmpStr = String(hexStr).substr(i * 2, 2);
        listHex.push(tmpStr);
      }
      //const uidCard = "0x" + listHex.reverse().join("");
      const uidCard = listHex.reverse().join("");
      this.findPersonByUid(uidCard.toUpperCase());
      console.log("uidCard:", uidCard);
    } else {
      NFCReadChars.push(String.fromCharCode(keyCode));
    }
  };

  findPersonByUid = async (uidCard) => {
    try {
      var matches = this.state.device.project.match(/\d+/g);
      const participant = (await axiosInstance.get(
        `/people/${uidCard}/${matches[0]}`
      )).data;
      if (participant) {
        console.log("new participant activation");
        console.log(participant);
        this.setState({ currentParticipant: participant });
        this.resetTimeOut();
      }
    } catch (e) {
      console.log(e);
    }
  };

  setItem = (item) => {
    this.setState({ currentConfiguratorItem: item });
  };

  trackReceiveViaEmailAction = (configuratorItem) => {
    const { currentParticipant, device } = this.state;
    const receiveViaEmailAction = {
      person: currentParticipant["@id"],
      project: device.project,
      device: device["@id"],
      product: typeof configuratorItem.product !== "undefined" ? configuratorItem.product["@id"] : null,
      productVersion: typeof configuratorItem.productVersion !== "undefined" ? configuratorItem.productVersion["@id"] : null
    };
    if (configuratorItem.productVersion && typeof configuratorItem.technicalDocument !== "undefined" && configuratorItem.productVersion.technicalDocument !== null) {
      receiveViaEmailAction.document = configuratorItem.productVersion.technicalDocument["@id"];
    } else if (configuratorItem.product && typeof configuratorItem.product.technicalDocument !== "undefined" && configuratorItem.product.technicalDocument !== null) {
      receiveViaEmailAction.document = configuratorItem.product.technicalDocument["@id"];
    }
    if (typeof device.application !== "undefined" && device.application !== null) {
      receiveViaEmailAction.application = device.application["@id"];
    }
    axiosInstance({
      method: "POST",
      url: "/receive_via_email_actions",
      data: JSON.stringify(receiveViaEmailAction)
    });
  };

  requestTestDrive = async (item) => {
    console.log("handle test drive request for ", item);
    const { currentParticipant, device } = this.state;
    if (currentParticipant === null) {
      return;
    }
    const testDriveRequestAction = {
      person: currentParticipant["@id"],
      project: device.project,
      device: device["@id"]
    };
    if (item.product !== null) {
      testDriveRequestAction.product = item.product["@id"];
    }
    if (item.productVersion !== null) {
      testDriveRequestAction.productVersion = item.productVersion["@id"];
    }
    if (typeof device.application !== "undefined" && device.application !== null) {
      testDriveRequestAction.application = device.application["@id"];
    }
    const response = await axiosInstance({
      method: "POST",
      url: "/test_drive_request_actions",
      data: JSON.stringify(testDriveRequestAction)
    });
    if (response.status === 201) {
      this.setState({ testDriveRequestSentSuccessfully: true });
      setTimeout(() => {
        this.setState({ testDriveRequestSentSuccessfully: false });
      }, 5000);
    } else {
      this.setState({ testDriveRequestSentError: true });
      setTimeout(() => {
        this.setState({ testDriveRequestSentError: false });
      }, 5000);
    }
  };

  receiveTechnicalDocumentViaEmail = async (item) => {
    console.log("handle receiveTechnicalDocumentViaEmail for ", item);
    const { currentParticipant } = this.state;
    if (currentParticipant === null) {
      return;
    }
    let url = null;
    if (item.productVersion !== null) {
      url = `/product_versions/${item.productVersion.id}/send-technical-document-via-email?person=${currentParticipant.id}`;
    } else if (item.product !== null) {
      url = `/products/${item.product.id}/send-technical-document-via-email?person=${currentParticipant.id}`;
    }
    const response = await axiosInstance({
      method: "POST",
      url: url,
      data: JSON.stringify({})
    });
    if (response.status === 201) {
      this.setState({ technicalDocSentSuccessfully: true });
      setTimeout(() => {
        this.setState({ technicalDocSentSuccessfully: false });
      }, 5000);
      this.trackReceiveViaEmailAction(item);
    } else {
      this.setState({ technicalDocSentError: true });
      setTimeout(() => {
        this.setState({ technicalDocSentError: false });
      }, 5000);
    }
  };

  render() {
    const {
      currentConfiguratorItem, config, deviceUID, currentParticipant,
      showScanMessage, technicalDocSentSuccessfully, technicalDocSentError,
      testDriveRequestSentSuccessfully, testDriveRequestSentError
    } = this.state;
    const items = config ? config.configuratorItems : [];
    return (
      <div className="App" style={{
        "backgroundColor": config && config.backgroundColor !== null ? config.backgroundColor : "white",
        "backgroundImage": (config && config.mainBackgroundImage) ? "url(" + config.mainBackgroundImage.contentUrl + ")" : ""
      }}>
        {deviceUID ? (
          <div className="wrapper-container">
            {/*{currentParticipant !== null && (*/}
            <Header brandLogoUrl={config && config.brandLogo ? config.brandLogo.contentUrl : ""}
                    configuratorItems={items} setItem={this.setItem}/>
            <div className={"items-container"} style={{ width: "100%", height: "100%" }}>
              {currentConfiguratorItem &&
              <ConfigurableItem currentParticipant={currentParticipant} item={currentConfiguratorItem}
                                requestTestDrive={this.requestTestDrive}
                                receiveTechnicalDocumentViaEmail={this.receiveTechnicalDocumentViaEmail}/>}
            </div>
            {/*)}*/}
            {showScanMessage && currentParticipant === null && config !== null && (
              <ScanMessage scanIcon={config.scanIcon} scanMessage={config.scanMessage}/>
            )}
          </div>
        ) : (
          <Identify getConfig={this.getConfig}/>
        )}
        {testDriveRequestSentSuccessfully === true &&
        <div className="notification-container">
          <p>Votre demande de test drive est envoyée avec succès</p>
        </div>
        }
        {testDriveRequestSentError === true &&
        <div className="notification-container">
          <p className={"error"}>Erreur d'envoi de votre demande de test drive</p>
        </div>
        }
        {technicalDocSentSuccessfully === true &&
        <div className="notification-container">
          <p>La fiche technique est envoyée avec succès</p>
        </div>
        }
        {technicalDocSentError === true &&
        <div className="notification-container">
          <p className={"error"}>Erreur d'envoi de la fiche technique</p>
        </div>
        }
      </div>
    );
  }
}

export default App;
